<template>
  <div class="home">
    <v-carousel 
      cycle
      light
      height="100vh" 
      interval="6000"
      hide-delimiter-background 
      :show-arrows="false">
      <v-carousel-item
        v-for="(slide, i) in slides" :key="i">
        <v-sheet class="slide__item background-position" :class="{ backgroundLeft : slide.focusLeft }" :style="{ backgroundImage: 'url(' + slide.img + ')' }">
          <v-row 
            class="fill-height"
            align="center"
            justify="center">
            <v-col class="slide__column">
              <div class="display-1 text-center slide__content">
                <div class="slide__content__wrapper">
                  <h2 v-html="slide.Title" class="slide__title"></h2>
                  <h3 v-html="slide.Subtitle" class="slide__subtitle"></h3>
                  <p v-html="slide.Description" class="slide__description"></p>
                  <a :href="slide.ctaLink" class="slide__btn">{{slide.ctaButton}}</a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

      <!-- Sections -->
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'Home',
  components: {
    SectionBanner
  },
  data() {
    return {
      slides: [
        {
          Title: "",
          Subtitle: "<span class='slide__subtitle--large'>We <strong>listen, understand,</strong> and <strong class='slide__title--underline'>help</strong></span>",
          Description: "NLA is a close-knit team of approachable, engaged professionals, with seasoned experience in <strong>law, finance, wealth</strong>, and <strong>property</strong>. We humanise the often daunting legal process and do all we can to make your business and life better.",
          ctaButton: "Talk to Us",
          ctaLink: "/contact",
          img: require("@/assets/img/Vuyani.jpg"),
          focusLeft: false
        },
        {
          Title: "Family Law",
          Subtitle: "<em>Untangling the ties that bind</em>",
          Description: "Issues arise within any family. We understand that, and can help you, with care, respect, and compassion.",
          ctaButton: "Read more about Family Law",
          ctaLink: "/family-law",
          img: require("@/assets/img/Helanie.jpg"),
          focusLeft: true
        }
      ],
      sections: [
        {
          Name: "What we do",
          Title: "Building your business",
          Subtitle: "Put the right blocks in place",
          Description: "From setting up and registering your company, to day to day governance issues, NLA can help you build the business that you’re working towards.",
          ctaButton: "Read More",
          ctaLink: "/building-your-business",
          img: require("@/assets/img/Natalie.jpg"),
          white: false,
          focusLeft: true
        },
        {
          Name: "What we do",
          Title: "Litigation",
          Subtitle: "Lawyer Up",
          Description: "Litigation can be a daunting experience for anyone not acquainted with the legal system. We understand this completely and can give you calm, careful counsel, throughout the process.",
          ctaButton: "Read More",
          ctaLink: "/litigation",
          img: require("@/assets/img/Vuyani2.jpg"),
          white: false,
          focusLeft: false
        },
        {
          Name: "Why Us?",
          Title: "We Listen",
          Subtitle: "",
          Description: "A lot of lawyers are great talkers. We’re good listeners. By understanding your problem, from your point of view, we can get a holistic view of the issues that you're facing.",
          ctaButton: "Read More",
          ctaLink: "/why-us",
          img: require("@/assets/img/Laura.jpg"),
          white: true,
          focusLeft: true
        },
        {
          Name: "Why Us?",
          Title: "What our clients say",
          Subtitle: "",
          Description: "",
          Decoration: require("@/assets/img/Inverted-commas-white.svg"),
          SlideSection: [ 
            "Natalie and I worked together on a sale and acquisition in partnership with a private equity fund. During the project, Natalie availed herself for all our meetings, even on short notice,  and was dedicated to ensuring we met our deadlines. She always provided great insight and considerations during the project. Her positive attitude and personality make her a pleasure to work with.”<br><br> - Melissa Gangen, Director, Digital Planet",
            "After a labour dispute with an employee we approached NLA Legal Inc for advice. We received fast accurate advice and documentation on how to proceed step by step. Not once were we left not knowing what to do and always got a swift reply and answer. Storage spot would like to thank NLA Legal for the amazing service.”<br><br> - Karen Bryant, Facility Manager, Storage Spot (Pty) Ltd"
          ],
          ctaButton: "",
          ctaLink: "",
          img: require("@/assets/img/Clients.jpg"),
          slider: true,
          white: true,
          focusLeft: false
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/scss/_variables.scss";
.slide {
  &__item {
    background-size: cover;
     &.background-position {
        background-position: center right;
      }
      &.backgroundLeft {
        background-position: center left;
      }
  }


    &__subtitle--large {
        font-family: 'EB Garamond', serif;
        margin-bottom: 15px;
        color: $dark-text;
        font-size: 80px;
        line-height: 120%;
        font-weight: 500;
        text-transform: none;
      }

  &__title {
    margin-bottom: 9px;
    font-family: $lato;
    color: $primary;
    font-size: 80px;
    line-height: 120%;
    text-transform: uppercase;
  }

  &__subtitle {
    font-family: $garamond;
    margin-bottom: 9px;
    font-size: 40px;
    line-height: 120%;
    font-weight: 600;
  }

  &__description {
    display: block;
    font-family: $lato;
    font-size: 18px;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  &__column {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }

  &__content {
    width: 100%;
    padding: 0px 10px 10px;
    border: 1px none #000;
    background-color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    &__wrapper {
      text-align: left;
      width: 50%;
      padding: 0px 10px 10px;
      border: 1px none #000;
      background-color: transparent;
    }
  }

  &__btn {
    margin-top: 2%;
    display: inline-block;
    padding: 16px 25px;
    border: 1px solid $primary;
    background-color: $btn-background;
    font-family: $lato;
    color: $primary;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: $primary;
      color: $btn-hover;
    }
  }
}

@media (max-width: 991px) {
.slide {
  &__title {
    font-size: 70px;
  } 

  &__content__wrapper {
     width: 60%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    background-color: hsla(0, 0%, 100%, 0.56);
  }
}
}
@media (max-width: 767px) {
  .slide {
    &__content {
      &__wrapper {
      width: 100%;
      }
    }
    &__title {
      font-size: 50px;
    }
    &__subtitle--large {
        font-size: 50px;
    }
  }
}
@media (max-width: 600px) {
  .slide {
    &__title {
      font-size: 30px;
    }
    &__subtitle--large {
        font-size: 30px;
        line-height: 3;
    }
    &__subtitle {
      font-size: 30px;
    }
  }
}
</style>